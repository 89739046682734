'use strict'

angular
  .module 'mundoAdmin.devices'
  .config ($stateProvider) ->
    $stateProvider
      .state 'devices',
        url: '/devices'
        parent: 'admin'
        views:
          '@admin':
            templateUrl: 'mundo-admin/devices/views/devices.tpl.html'
            controller: 'TabsCtrl'
            controllerAs: 'devices'
        deepStateRedirect: { default: { state: 'devices.overview' } },

      .state 'devices.overview',
        url: '/overview'
        data:
          'selectedTab': 0
        views:
          'devices@devices':
            templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
            controller: 'DataTableCtrl'
            controllerAs: 'listCtrl'
            resolve:
              entityManager: (DeviceManager) ->
                DeviceManager
              pageTitle: ->
                'app.admin.pageTitles.devices.devices'
              pageDescription: ->
                'app.admin.descriptions.devices.devices'
              deleteBoxTitle: ->
                'app.admin.actions.devices.deleteDevice'
              deleteBoxBody: ->
                'app.admin.actions.devices.deleteDevice'
              AddBoxTitle: ->
                'app.admin.actions.devices.addDevice'
              editBoxTitle: ->
                'app.admin.actions.devices.editDevice'
              loadFilterParams: ->
                {}
      .state 'devices.types',
        url: '/types'
        data:
          'selectedTab': 1
        views:
          'types@devices':
            templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
            controller: 'DataTableCtrl'
            controllerAs: 'listCtrl'
            resolve:
              entityManager: (DeviceTypeManager) ->
                DeviceTypeManager
              pageTitle: ->
                'app.admin.pageTitles.devices.deviceTypes'
              pageDescription: ->
                'app.admin.descriptions.devices.deviceTypes'
              deleteBoxTitle: ->
                'app.admin.actions.devices.deleteDeviceType'
              deleteBoxBody: ->
                'app.admin.actions.devices.deleteDeviceType'
              AddBoxTitle: ->
                'app.admin.actions.devices.addDeviceType'
              editBoxTitle: ->
                'app.admin.actions.devices.editDeviceType'
              loadFilterParams: ->
                {}
